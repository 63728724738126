import {
    SITESEARCH_GET_SEARCHSUGGESTIONS,
    SITESEARCH_GET_SEARCHRESULT,
    SITESEARCH_SET_ISLOADING,
    SITESEARCH_SET_HEADER_INPUT_STATE,
    SITESEARCH_SET_INPUT_VALUE
} from '../actions/types';

const initialState = {
    isLoading: false,
    headerInputIsExpanded: false,
    inputValue: '',
    searchSuggestions: [],
    searchResult: null
};

export default function (state = initialState, action) {
    switch (action.type) {
    case SITESEARCH_SET_ISLOADING:
        return {
            ...state,
            isLoading: action.payload
        };
    case SITESEARCH_SET_HEADER_INPUT_STATE:
        return {
            ...state,
            headerInputIsExpanded: action.payload
        };
    case SITESEARCH_SET_INPUT_VALUE:
        return {
            ...state,
            inputValue: action.payload
        };
    case SITESEARCH_GET_SEARCHSUGGESTIONS:
        return {
            ...state,
            searchSuggestions: action.payload,
            isLoading: false
        };
    case SITESEARCH_GET_SEARCHRESULT:
        return {
            ...state,
            searchResult: action.payload,
            isLoading: false
        };
    default:
        return state;
    }
}
