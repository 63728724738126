import React from 'react';
import PropTypes from 'prop-types';
import { translations } from '../../sharedJs/translations';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';
import SubListItem from './SubListItem';

const miljomalIcon = '/assets/miljostatus/icons/ikon_miljomal_globalnavigasjon.svg';
const kartIcon = '/assets/miljostatus/icons/ikon_kart_globalnavigasjon.svg';
const dataIcon = '/assets/miljostatus/icons/icon-mstatus-data.svg';
const stateIcon = '/assets/miljostatus/icons/ikon_tilstand_forside_black.svg';
const seaIndicatorsIcon = '/assets/miljostatus/icons/icon_havindikator_forside.svg';
const environmentStoriesIcon = '/assets/miljostatus/icons/icon_miljohistorier_forside.svg';


const TopListItem = (props) => {
    const getIcon = (iconName) => {
        switch (iconName) {
        case 'miljomal':
            return miljomalIcon;
        case 'kart':
            return kartIcon;
        case 'environmentData':
            return dataIcon;
        case 'tilstand':
            return stateIcon;
        case 'havindikator':
            return seaIndicatorsIcon;                
        case 'miljohistorier':
            return environmentStoriesIcon;                
        default:
            return miljomalIcon;
        }
    };

    const children = props.getLevelItemChildren(props.data.id);

    const translate = translations(props.language.name);

    return (
        <li
            className={`c_nav-list__item c_nav-list__item--level-block${props.topLevelBlock}`}
        >
            <EpiLink className="c_nav-list__item-content" url={props.data.url} onFocus={() => props.focusHandler()}>
                {props.data.icon &&
                <SvgElement
                    src={getIcon(props.data.icon)}
                    title={`${translate.App.Icon} for ${props.data.pageName}`}
                    desc={translate.App.Choose}
                    className="c_nav-list-item__icon"
                />
                }
                <span className="c_nav-list__item--header">{props.data.pageName}</span>
            </EpiLink>

            {children && children.length > 0 && props.data.topLevelBlock === 1 &&
                <ul className="c_nav-list c_nav-list--sub-level">
                    {children.map(listItem => (
                        <SubListItem
                            key={`${listItem.id}_${listItem.url}_${listItem.parentId}`}
                            data={listItem}
                            changeLevelToShow={props.changeLevelToShow}
                            active={(props.pathname === listItem.url) || (`/#${props.pathname}` === listItem.url)}
                            focusHandler={props.focusHandler}
                            language={props.language}
                            closeMenu={props.closeMenu}
                        />
                    ))}
                </ul>
            }
        </li>
    );
};

TopListItem.propTypes = {
    data: PropTypes.object.isRequired,
    focusHandler: PropTypes.func,
    topLevelBlock: PropTypes.number,
    icon: PropTypes.any,
    language: PropTypes.object,
    getLevelItemChildren: PropTypes.func,
    changeLevelToShow: PropTypes.func,
    pathname: PropTypes.string
};

export default TopListItem;
