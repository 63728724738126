import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import SvgElement from '../uiElements/svgElement/SvgElement';

import './_surveyPopUp.scss';

const StatsIcon = '/assets/miljostatus/icons/icon-question.svg';
const CloseIcon = '/assets/miljostatus/icons/icon-close-white.svg';
const RedirIcon = '/assets/miljostatus/icons/icon-redirect-white.svg';
const surveyCookieName = 'mstatus_survey_completed';

const SurveyPopUp = (props) => {
    const { surveyData } = props;
    const cookie = Cookies.get(surveyCookieName);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!cookie) {
            setTimeout(() => {
                setShow(true);
            }, 2000);
        }
    }, []);

    const handleCloseButton = () => {
        // set cookie to wait xx mins before asking again
        Cookies.set(surveyCookieName, 'wait', { expires: surveyData.delay || 0.6 });
        setShow(false);
    };

    const handleDecline = () => {
        Cookies.set(surveyCookieName, 'decline', { expires: 30 });
        setShow(false);
    };

    const handleCompleteSurvey = () => {
        // set cookie and redirect to survey
        Cookies.set(surveyCookieName, 'complete', { expires: 365 });
        setShow(false);
        window.open(surveyData.url.uri, '_blank');
    };

    if (show && surveyData && surveyData.url && surveyData.url.uri) {
        return (
            <div className="c_survey-pop-up">
                <div className="c_survey-pop-up__icon-wrapper">
                    <SvgElement
                        src={StatsIcon}
                        className="c_survey-pop-up__icon"
                    />
                </div>
                <button
                    className="c_survey-pop-up__close-button"
                    onClick={() => handleCloseButton()}
                >
                    <SvgElement
                        src={CloseIcon}
                        className="c_survey-pop-up__close-icon"
                    />
                </button>
                <div className="c_survey-pop-up__inner">
                    <h2 className="c_survey-pop-up__header">{surveyData.title}</h2>
                    {surveyData.text &&
                        <p>{surveyData.text}</p>
                    }
                    <div className="c_survey-pop-up__actions">
                        <button
                            onClick={() => handleDecline()}
                            type="button"
                            className="c_survey-pop-up__actions--no"
                        >
                            Nei, takk
                        </button>
                        <button
                            onClick={() => handleCompleteSurvey()}
                            type="button"
                            className="c_survey-pop-up__actions--yes"
                            title="Åpne undersøkelsen i en ny fane"
                        >
                            Gå til undersøkelsen
                            <SvgElement
                                src={RedirIcon}
                                className="c_survey-pop-up__actions__icon"
                                title="Pil"
                                desc="Pil som peker til høyre"
                            />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

SurveyPopUp.propTypes = {
    surveyData: PropTypes.object
};

export default SurveyPopUp;
