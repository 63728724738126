import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { translations } from '../../sharedJs/translations';
import SvgElement from '../uiElements/svgElement/SvgElement';

import '../../styles/_utilities.scss';
import './_headerSearchField.scss';

import { setHeaderInputExpandedState, setInputValue, fetchSearchResult } from '../../actions/sitesearchActions';

const searchIconBlue = '/assets/miljostatus/icons/icon-search-blue.svg';
const crossIconBlue = '/assets/miljostatus/icons/icon-close-blue.svg';


class HeaderSearchField extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.searchInputRef = React.createRef();
    }


    componentWillMount() {
        this.timer = undefined;
    }

    componentDidMount() {
        if (this.props.usedOnSearchPage) {
            this.searchInputRef.current.focus();
        }
    }

    onFocus() {
        this.props.setHeaderInputExpandedState(true);
    }

    onChange(e) {
        if (!this.props.usedOnSearchPage) {
            e.persist();
            this.props.setInputValue(e.target.value, false);
            if (e.target.value.length > 2) {
                if (this.props.searchOnChange) {
                    if (this.timer) {
                        clearTimeout(this.timer);
                    }
                    this.timer = setTimeout(() => {
                        this.props.fetchSearchResult(e.target.value, {});
                        this.pushSearchChangeToUrl(e.target.value);
                    }, 500);
                } else {
                    if (this.timer) {
                        clearTimeout(this.timer);
                    }
                    this.timer = setTimeout(() => {
                        this.props.setInputValue(e.target.value, true);
                    }, 500);
                }
            }
        } else {
            this.props.setInputValue(e.target.value, false);
        }
    }

    onBlur() {
        if (this.props.inputValue.length < 1) {
            this.props.setHeaderInputExpandedState(false);
        }
    }

    onKeyPress(e) {
        const code = (e.keyCode ? e.keyCode : e.which);
        if (!this.props.searchOnChange && code === 13) {
            e.preventDefault();
            const encodedQuery = encodeURIComponent(e.target.value);

            if (this.props.usedOnSearchPage) {
                this.props.fetchSearchResult(this.props.inputValue, {});
                this.pushSearchChangeToUrl(this.props.inputValue);
            } else {
                window.location = `${this.props.searchPageUrl}?searchString=${encodedQuery}`;
            }
        }
    }

    onClear() {
        this.props.setInputValue('');
        this.searchInputRef.current.focus();
    }

    onSearchButtonClick() {
        if (this.props.usedOnSearchPage) {
            this.props.fetchSearchResult(this.props.inputValue, {});
            this.pushSearchChangeToUrl(this.props.inputValue);
        } else {
            this.navigateToSearchLocation();
        }
    }

    getIcon(icon) {
        switch (icon) {
        case 'search':
            return searchIconBlue;
        case 'close':
            return crossIconBlue;
        default:
            return null;
        }
    }

    pushSearchChangeToUrl(stringValue) {
        setTimeout(() => {
            window.history.pushState(stringValue, '', `${this.props.searchPageUrl}?searchString=${stringValue}`);
        }, 1200);
    }

    navigateToSearchLocation() {
        if (this.props.inputValue && this.props.inputValue.length > 0) {
            window.location = `${this.props.searchPageUrl}?searchString=${this.props.inputValue}`;
        }
    }

    render() {
        const translate = translations(this.props.language.name);
        return (
            <div
                role="search"
                aria-label="Søk på hele nettstedet"
                className={`c_search-field ${this.props.headerInputIsExpanded ?
                    'c_search-field--focused' : ''} ${this.props.alwaysFullWidth ?
                    'c_search-field--full-width' : ''} ${this.props.language.name === 'en' ?
                    'c_search-field--english' : ''} ${this.props.className}`}
            >
                <label htmlFor={this.props.id}>
                    <span className="u_visuallyhidden">
                        {this.props.usedOnSearchPage ? `Søkefelt med innhold: ${this.props.inputValue}` : 'Søk'}
                    </span>
                    <button
                        className="c_search-field__search-button"
                        onClick={() => this.onSearchButtonClick()}
                        tabIndex="-1"
                        aria-hidden="true"
                    >
                        <span className="u_visuallyhidden">
                            Trykk for å søke
                        </span>
                        <SvgElement
                            src={this.getIcon('search')}
                            title="Søk"
                            desc="Søkefelt"
                            className="c_search-field__icon"
                        />
                    </button>
                    <input
                        type="search"
                        placeholder={translate.App.Search}
                        className="c_search-field__input"
                        name={this.props.id}
                        id={this.props.id}
                        value={this.props.inputValue}
                        onChange={e => this.onChange(e)}
                        onFocus={() => this.onFocus()}
                        onBlur={() => this.onBlur()}
                        onKeyPress={e => this.onKeyPress(e)}
                        ref={this.searchInputRef}
                        autoComplete="off"
                        aria-label={translate.App.SearchField}
                    />
                    {this.props.headerInputIsExpanded && this.props.inputValue && this.props.inputValue.length > 0 &&
                    <button
                        className="c_search-field__clear-button"
                        onClick={() => this.onClear()}
                        tabIndex="-1"
                        aria-hidden="true"
                    >
                        <SvgElement
                            src={this.getIcon('close')}
                            title={translate.App.Empty}
                            desc={translate.App.EmptySearchField}
                            className="c_search-field__icon"
                        />
                    </button>
                    }
                </label>
            </div>
        );
    }
}

HeaderSearchField.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputValue: PropTypes.string.isRequired,
    setHeaderInputExpandedState: PropTypes.func.isRequired,
    setInputValue: PropTypes.func.isRequired,
    fetchSearchResult: PropTypes.func.isRequired,
    headerInputIsExpanded: PropTypes.bool.isRequired,
    searchOnChange: PropTypes.bool,
    alwaysFullWidth: PropTypes.bool,
    usedOnSearchPage: PropTypes.bool,
    language: PropTypes.object,
    searchPageUrl: PropTypes.string
};

HeaderSearchField.defaultProps = {
    searchOnChange: false,
    alwaysFullWidth: false,
    usedOnSearchPage: false
};

const mapStateToProps = state => ({
    headerInputIsExpanded: state.siteSearch.headerInputIsExpanded,
    inputValue: state.siteSearch.inputValue,
    searchSuggestions: state.siteSearch.searchSuggestions,
    language: state.app.language
});

export default connect(mapStateToProps, {
    setHeaderInputExpandedState,
    setInputValue,
    fetchSearchResult
})(HeaderSearchField);
