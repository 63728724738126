import {
    DATACHART_GET_CHART,
    DATACHART_GET_INDICATOR_CHARTS,
    DATACHART_SET_ISLOADING
} from '../actions/types';

const initialState = {
    isLoading: false,
    chartData: [],
    indicatorCharts: null
};

const getChartDataState = (state, action) => {
    const chartDataState = JSON.parse(JSON.stringify(state.chartData));
    const selectedDatasetIndex = chartDataState.findIndex(item => item.datasetId === action.payload.datasetId);
    if (selectedDatasetIndex >= 0) {
        chartDataState[selectedDatasetIndex].data = action.payload.data;
    } else {
        chartDataState.push({
            datasetId: action.payload.datasetId,
            data: action.payload.data
        });
    }
    return chartDataState;
};

export default function (state = initialState, action) {
    switch (action.type) {
    case DATACHART_SET_ISLOADING:
        return {
            ...state,
            isLoading: action.payload
        };
    case DATACHART_GET_CHART:
        return {
            ...state,
            chartData: getChartDataState(state, action),
            isLoading: false
        };
    case DATACHART_GET_INDICATOR_CHARTS:
        return {
            ...state,
            indicatorCharts: action.payload,
            isLoading: false
        };
    default:
        return state;
    }
}
