import React from 'react';
import PropTypes from 'prop-types';
import SubListItem from './SubListItem';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';

const arrowUp = '/assets/miljostatus/icons/icon_breadcrumbs_arrow.svg';
const closeWhite = '/assets/miljostatus/icons/icon-close-white.svg';

const SubLevelList = props => (
    <div>
        <div className="c_nav-section-list c_nav-section-list--sub-level">
            <div className="c_nav-section-list__item">
                <div className="c_nav-section-list__item__header">
                    <div className="c_nav-section-list__level-btns-wrapper">
                        <button
                            className="c_section-list-item__level-up-button"
                            onClick={() => props.changeLevelToShow(1)}
                            onFocus={() => props.focusHandler()}
                        >
                            <SvgElement
                                src={arrowUp}
                                title={props.translate.App.GoToTopLevel}
                                desc={props.translate.App.ArrowRight}
                            />
                            <span>{props.translate.App.TopLevel}</span>
                        </button>

                        {props.parentTree && props.parentTree.length > 0 && props.parentTree.map(item => (
                            <button
                                key={`sub-level-button-${item.id}`}
                                className="c_section-list-item__level-up-button"
                                onClick={() => props.changeLevelToShow(item.id)}
                                onFocus={() => props.focusHandler()}
                            >
                                <SvgElement
                                    src={arrowUp}
                                    title={`${props.translate.App.GoTo} ${parseInt(item.id, 10) === 1 ?
                                        props.translate.App.TopLevel.toLowerCase() : item.pageName}`}
                                    desc={props.translate.App.ArrowRight}
                                />
                                <span>{parseInt(item.levelNum, 10) === 1 ? props.translate.App.TopLevel : item.pageName}</span>
                            </button>
                        ))}
                    </div>
                    <div className="c_close-button__container">
                        <button
                            className="c_close-button"
                            onClick={() => props.closeMenu()}
                            onFocus={() => props.focusHandler()}
                            aria-expanded={props.isOpen}
                        >
                            <span className="c_close-button__inner-wrapper">
                                <span className="c_close-button__text">{props.translate.App.Close}</span>
                                <SvgElement
                                    src={closeWhite}
                                    title={props.translate.App.CloseMenu}
                                    desc={props.translate.App.ButtonToCloseMenu}
                                />
                            </span>
                        </button>
                    </div>
                </div>

                {(props.pathname === props.levelObject.url ||
                    props.showLevelId === props.levelObject.id) ?
                    <div // eslint-disable-line
                        className={`c_section-list-item__title${(props.pathname === props.levelObject.url ||
                            props.pathname === props.levelObject.url) ? ' u_font-weight-medium' : ''}`}
                    >
                        <span className="c_section-list-item-title__text">
                            {props.levelObject.pageName}
                        </span>
                    </div>
                    :
                    <EpiLink
                        url={props.levelObject.url}
                        className="c_section-list-item__title"
                        onFocus={() => props.focusHandler()}
                    >
                        <span className="c_section-list-item-title__text">
                            {props.levelObject.pageName}
                        </span>
                    </EpiLink>
                }
                <ul className="c_nav-list c_nav-list--sub-level">
                    {props.menuItems.map((listItem) => {
                        if (listItem.parentId === props.levelObject.id) {
                            return (
                                <SubListItem
                                    key={`${listItem.id}_${listItem.url}_${listItem.parentId}`}
                                    data={listItem}
                                    changeLevelToShow={props.changeLevelToShow}
                                    active={(props.pathname === listItem.url) || (`/#${props.pathname}` === listItem.url)}
                                    focusHandler={props.focusHandler}
                                    language={props.language}
                                    closeMenu={props.closeMenu}
                                />
                            );
                        }
                        return null;
                    })}
                </ul>
            </div>
        </div>
    </div>
);

SubLevelList.propTypes = {
    focusHandler: PropTypes.func,
    changeLevelToShow: PropTypes.func,
    isOpen: PropTypes.bool,
    translate: PropTypes.object,
    language: PropTypes.object,
    pathname: PropTypes.string,
    menuItems: PropTypes.array,
    levelObject: PropTypes.object,
    showLevelId: PropTypes.node,
    parentTree: PropTypes.array,
    closeMenu: PropTypes.func
};

export default SubLevelList;
