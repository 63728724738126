import {
    FEEDBACKBAR_RESET, FEEDBACKBAR_SET_ISOPEN, FEEDBACKBAR_SEND_FEEDBACK,
    FEEDBACKBAR_POST_FEEDBACK_MESSAGE, FEEDBACKBAR_SET_ISLOADING, FEEDBACKBAR_TYPE_NEGATIVE
} from '../actions/types';

const initialState = {
    isLoading: false,
    isOpen: true,
    feedbackType: '',
    feedbackTypeSent: false,
    feedbackMessagePosted: false
};

export default function (state = initialState, action) {
    switch (action.type) {
    case FEEDBACKBAR_RESET:
        return {
            isLoading: false,
            isOpen: true,
            feedbackType: '',
            feedbackTypeSent: false,
            feedbackMessagePosted: false
        };
    case FEEDBACKBAR_SET_ISLOADING:
        return {
            ...state,
            isLoading: action.payload
        };
    case FEEDBACKBAR_SET_ISOPEN:
        return {
            ...state,
            isOpen: action.payload
        };
    case FEEDBACKBAR_SEND_FEEDBACK:
        return {
            ...state,
            feedbackTypeSent: true,
            feedbackType: action.payload,
            isLoading: false,
            isOpen: action.payload === FEEDBACKBAR_TYPE_NEGATIVE
        };
    case FEEDBACKBAR_POST_FEEDBACK_MESSAGE:
        return {
            ...state,
            feedbackMessagePosted: action.payload,
            isLoading: false
        };
    default:
        return state;
    }
}
