import {
    MAP_GET_SEARCHRESULT,
    MAP_SET_ISLOADING,
    MAP_SET_EXTENT
} from '../actions/types';

const initialState = {
    isLoading: false,
    showResultList: false,
    searchResult: [],
    extent: '',
    mapPin: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
    case MAP_SET_ISLOADING:
        return {
            ...state,
            isLoading: action.payload
        };
    case MAP_GET_SEARCHRESULT:
        return {
            ...state,
            searchResult: action.payload,
            isLoading: false,
            showResultList: true
        };

    case MAP_SET_EXTENT:
        return {
            ...state,
            extent: action.payload.extent,
            mapPin: action.payload.mapPin,
            isLoading: false,
            showResultList: false
        };
    default:
        return state;
    }
}
