import { GLOSSARY_SET_ISOPEN } from './types';

export const showGlossary = (glossaryIsOpen, glossaryText, targetOffset) => (dispatch) => {
    dispatch({
        type: GLOSSARY_SET_ISOPEN,
        payload: {
            isOpen: glossaryIsOpen,
            text: glossaryText,
            offset: targetOffset
        }
    });
};

export default showGlossary;
