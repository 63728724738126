export default function getComponentTypeForContent(content, components) {
    // Here we will try to find a component that matches the content type name.
    for (let i = (content.contentType.length - 1); i >= 0; i--) {
        // if (components[content.contentType[i]]) {
        //    return content.contentType[i];
        // }
        for (let j = 0; j < components.length; j++) {
            if (components[j].id === content.contentType[i]) {
                return components[j];
            }
        }
    }
    return null;
}
