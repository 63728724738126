export const FEEDBACKBAR_TYPE_POSITIVE = 'FEEDBACKBAR_TYPE_POSITIVE';
export const FEEDBACKBAR_TYPE_NEGATIVE = 'FEEDBACKBAR_TYPE_NEGATIVE';
export const FEEDBACKBAR_RESET = 'FEEDBACKBAR_RESET';
export const FEEDBACKBAR_SET_ISLOADING = 'FEEDBACKBAR_SET_ISLOADING';
export const FEEDBACKBAR_SET_ISOPEN = 'FEEDBACKBAR_SET_ISOPEN';
export const FEEDBACKBAR_SEND_FEEDBACK = 'FEEDBACKBAR_SEND_FEEDBACK';
export const FEEDBACKBAR_POST_FEEDBACK_MESSAGE = 'FEEDBACKBAR_POST_FEEDBACK_MESSAGE';

export const SITESEARCH_SET_HEADER_INPUT_STATE = 'SITESEARCH_HEADER_INPUT_ISEXPANDED';
export const SITESEARCH_SET_INPUT_VALUE = 'SITESEARCH_SET_INPUT_VALUE';
export const SITESEARCH_SET_ISLOADING = 'SITESEARCH_SET_ISLOADING';
export const SITESEARCH_GET_SEARCHSUGGESTIONS = 'SITESEARCH_GET_SEARCHSUGGESTIONS';
export const SITESEARCH_GET_SEARCHRESULT = 'SITESEARCH_GET_SEARCHRESULT';
export const LOAD_EPIDATAMODEL = 'LOAD_EPIDATAMODEL';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const UPDATE_MODEL_BY_CONTENT_LINK = 'UPDATE_MODEL_BY_CONTENT_LINK';
export const SET_EPIMODEL_ISLOADING = 'SET_EPIMODEL_ISLOADING';

export const GLOSSARY_SET_ISOPEN = 'GLOSSARY_SET_ISOPEN';

export const MAP_SET_ISLOADING = 'MAP_SET_ISLOADING';
export const MAP_GET_SEARCHRESULT = 'MAP_GET_SEARCHRESULT';
export const MAP_SET_EXTENT = 'MAP_SET_EXTENT';

export const DATACHART_SET_ISLOADING = 'DATACHART_SET_ISLOADING';
export const DATACHART_GET_CHART = 'DATACHART_GET_CHART';
export const DATACHART_GET_INDICATOR_CHARTS = 'DATACHART_GET_INDICATOR_CHARTS';
