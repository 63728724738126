import { UPDATE_CONTEXT, LOAD_EPIDATAMODEL, UPDATE_MODEL_BY_CONTENT_LINK, SET_EPIMODEL_ISLOADING } from '../actions/types';

const initialState = {
    inEditMode: false,
    isEditable: false,
    isLoading: false,
    epiDataModel: {},
    epiDataModelLoaded: false,
    language: {
        displayName: 'Norsk',
        name: 'no'
    }
};

function appReducer(state = initialState, action) {
    if (action.type === UPDATE_CONTEXT) {
        return {
            ...state,
            inEditMode: action.payload.inEditMode,
            isEditable: action.payload.isEditable
        };
    }

    if (action.type === SET_EPIMODEL_ISLOADING) {
        return {
            ...state,
            isLoading: action.payload
        };
    }

    if (action.type === LOAD_EPIDATAMODEL) {
        return {
            ...state,
            epiDataModel: action.payload,
            epiDataModelLoaded: true,
            language: action.payload.language
        };
    }

    if (action.type === UPDATE_MODEL_BY_CONTENT_LINK) {
        return {
            ...state,
            epiDataModel: action.payload,
            epiDataModelLoaded: true,
            language: action.payload.language
        };
    }

    return state;
}

export default appReducer;
