import React from 'react';
import PropTypes from 'prop-types';
import EpiLink from '../links/epiLink/EpiLink';
import SvgElement from '../uiElements/svgElement/SvgElement';
import './_breadcrumbs.scss';

const arrowRight = '/assets/miljostatus/icons/icon-arrow-right-black.svg';

const BreadCrumbs = props => (
    <div className="c_header-breadcrumbs u_hidden--mobile">
        <div className="g_container">
            <nav aria-label="Brødsmulesti" className="c_header-breadcrumbs__list">
                {props.breadcrumbs.map((crumb, index) => {
                    if (index === props.breadcrumbs.length - 1) {
                        return (
                            <div
                                key={`breadcrumb_key__${crumb.id}`}
                                className="c_header-breadcrumbs__list__item"
                            >
                                {crumb.text}
                            </div>
                        );
                    }
                    return (
                        <div
                            key={`breadcrumb_key__${crumb.id}`}
                            className="c_header-breadcrumbs__list__item"
                        >
                            <EpiLink
                                url={crumb.url}
                            >{crumb.text}
                            </EpiLink>
                            <SvgElement
                                src={arrowRight}
                                title="Pil høyre"
                                desc="Pil som peker til neste lenke"
                            />
                        </div>
                    );
                })}
            </nav>
        </div>
    </div>
);

BreadCrumbs.propTypes = {
    breadcrumbs: PropTypes.array
};

export default BreadCrumbs;
