import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TopListItem from './TopListItem';

const TopLevelList = (props) => {
    const topLevelItems = props.menuItems.filter(item => item.topLevelBlock === 1);
    const itemsLevel2 = props.menuItems.filter(item => item.topLevelBlock === 2);
    const items = props.menuItems.filter(item => item.topLevelBlock > 2);

    return (
        <Fragment>
            <div className="c_nav-section-list">
                <ul className="c_nav-list c_nav-list--top-level">
                    {topLevelItems.map((levelItem) => {
                        if (levelItem.levelNum === 2) {
                            return (
                                <TopListItem
                                    key={`${levelItem.id}_${levelItem.url}_${levelItem.topLevelBlock}`}
                                    data={levelItem}
                                    changeLevelToShow={props.changeLevelToShow}
                                    active={props.pathname === levelItem.url}
                                    focusHandler={props.focusHandler}
                                    topLevelBlock={levelItem.topLevelBlock}
                                    language={props.language}
                                    getLevelItemChildren={props.getLevelItemChildren}
                                    pathname={props.pathname}
                                    closeMenu={props.closeMenu}
                                />
                            );
                        }
                        return false;
                    })}
                </ul>

                <ul className="c_nav-list c_nav-list--top-level c_nav-list c_nav-list--top-level--flex">
                    {itemsLevel2.map((levelItem) => {
                        if (levelItem.levelNum === 2) {
                            return (
                                <TopListItem
                                    key={`${levelItem.id}_${levelItem.url}_${levelItem.topLevelBlock}`}
                                    data={levelItem}
                                    changeLevelToShow={props.changeLevelToShow}
                                    active={props.pathname === levelItem.url}
                                    focusHandler={props.focusHandler}
                                    topLevelBlock={levelItem.topLevelBlock}
                                    language={props.language}
                                    getLevelItemChildren={props.getLevelItemChildren}
                                    pathname={props.pathname}
                                    closeMenu={props.closeMenu}
                                />
                            );
                        }
                        return false;
                    })}
                </ul>

                <ul className="c_nav-list c_nav-list--level3 c_nav-list--top-level c_nav-list--top-level--flex">
                    {items.map((levelItem) => {
                        if (levelItem.levelNum === 2) {
                            return (
                                <TopListItem
                                    key={`${levelItem.id}_${levelItem.url}_${levelItem.topLevelBlock}`}
                                    data={levelItem}
                                    changeLevelToShow={props.changeLevelToShow}
                                    active={props.pathname === levelItem.url}
                                    focusHandler={props.focusHandler}
                                    topLevelBlock={levelItem.topLevelBlock}
                                    language={props.language}
                                    getLevelItemChildren={props.getLevelItemChildren}
                                    pathname={props.pathname}
                                    closeMenu={props.closeMenu}
                                />
                            );
                        }
                        return false;
                    })}
                </ul>
            </div>
        </Fragment>
    );
};

TopLevelList.propTypes = {
    focusHandler: PropTypes.func,
    changeLevelToShow: PropTypes.func,
    language: PropTypes.object,
    pathname: PropTypes.string,
    menuItems: PropTypes.array,
    getLevelItemChildren: PropTypes.func
};

export default TopLevelList;
