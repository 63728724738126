import axios from 'axios';
import { stripStringForHtml } from '../sharedJs/utilityFunctions';
import {
    SITESEARCH_SET_HEADER_INPUT_STATE,
    SITESEARCH_GET_SEARCHSUGGESTIONS,
    SITESEARCH_GET_SEARCHRESULT,
    SITESEARCH_SET_ISLOADING,
    SITESEARCH_SET_INPUT_VALUE
} from './types';

export const setHeaderInputExpandedState = isExpanded => (dispatch) => {
    dispatch({
        type: SITESEARCH_SET_HEADER_INPUT_STATE,
        payload: isExpanded
    });
};

const dispatchSearchSuggestions = suggestions => (
    {
        type: SITESEARCH_GET_SEARCHSUGGESTIONS,
        payload: suggestions
    }
);

export const fetchSearchSuggestions = searchString => (dispatch) => {
    const strippedSearchString = stripStringForHtml(searchString);
    dispatch({
        type: SITESEARCH_SET_ISLOADING,
        payload: true
    });
    axios.get(`${process.env.REACT_APP_API_URL}miljostatussearch/searchsuggestions?searchString=${strippedSearchString}`)
        .then((suggestionsData) => {
            dispatch(dispatchSearchSuggestions(suggestionsData.data));
        })
        .catch((error) => {
            throw error;
        });
};

export const setInputValue = (value, alsoGetSearchSuggestions) => (dispatch) => {
    dispatch({
        type: SITESEARCH_SET_INPUT_VALUE,
        payload: value
    });
    if (alsoGetSearchSuggestions) {
        dispatch(fetchSearchSuggestions(value));
    }
};

const dispatchSearchResult = result => (
    {
        type: SITESEARCH_GET_SEARCHRESULT,
        payload: result
    }
);

export const fetchSearchResult = (searchString, infoObject) => (dispatch) => {
    const strippedSearchString = stripStringForHtml(searchString);
    const searchObject = {
        searchString: strippedSearchString,
        ...infoObject
    };
    dispatch({
        type: SITESEARCH_SET_ISLOADING,
        payload: true
    });
    axios.post(
        `${process.env.REACT_APP_API_URL}miljostatussearch/search?searchString=${strippedSearchString}`,
        JSON.parse(JSON.stringify(searchObject))
    ).then((searchData) => {
        dispatch(dispatchSearchResult(searchData.data));
    })
        .catch((error) => {
            throw error;
        });
};
