import { GLOSSARY_SET_ISOPEN } from '../actions/types';

const initialState = {
    isOpen: false,
    text: '',
    offset: {
        top: 0,
        left: 'auto',
        right: 'auto'
    },
    arrowLeft: true
};

export default function (state = initialState, action) {
    switch (action.type) {
    case GLOSSARY_SET_ISOPEN:
        return {
            ...state,
            isOpen: action.payload.isOpen,
            text: action.payload.text,
            offset: action.payload.offset,
            arrowLeft: action.payload.offset.arrowLeft
        };
    default:
        return state;
    }
}
