import store from './store';
import api from './api/api';
// import { UPDATE_CONTEXT } from '@/Scripts/store/modules/epiContext';
// import { UPDATE_MODEL_BY_CONTENT_LINK } from '@/Scripts/store/modules/epiDataModel';

import { updateContext, updateModelByContentLink } from './actions/index';

const registerContentSavedEvent = (isEditable) => {
    /**
     * If we enter an editable context we want to enable On-Page Editing
     * and therefore start listening to the "beta/contentSaved" event to
     * be notified when content has been edited. We then update the
     * model and trigger an update of the components.
     */

    if (isEditable) {
        window.epi.subscribe('beta/contentSaved', (message) => {
            // store.dispatch(UPDATE_MODEL_BY_CONTENT_LINK, message.contentLink);

            console.log('In contentSaved-event...'); // eslint-disable-line

            api.getContentByContentLink(message.contentLink).then((response) => {
                console.log('Response received from getting content by contentLink'); // eslint-disable-line
                console.log(response.data); // eslint-disable-line
                store.dispatch(updateModelByContentLink(response.data));
            });
        });
    }
};

// Listen to the `beta/epiReady` event to update the `context` property.
window.addEventListener('load', () => {
    // Expect `epi` to be there after the `load` event. If it's not then we're
    // not in any editing context.
    if (!window.epi) {
        return;
    }

    function setContext() {
        // The event only has `isEditable`, but the epi object has both.
        const context = {
            inEditMode: window.epi.beta.inEditMode,
            isEditable: window.epi.beta.isEditable
        };
        // Make the context available to all Vue components.
        // store.commit(UPDATE_CONTEXT, context);

        store.dispatch(updateContext(context));

        registerContentSavedEvent(context.isEditable);
    }

    // Check for beta and that ready is an actual true value (not just truthy).
    if (window.epi.ready === true) {
        // `beta/epiReady` already fired.
        setContext();
        // The subscribe method won't be available in View mode.
    } else if (window.epi.subscribe) {
        // Subscribe if the `beta/epiReady` event hasn't happened yet.
        window.epi.subscribe('epiReady', () => setContext());
    }
});
