import { UPDATE_CONTEXT, LOAD_EPIDATAMODEL, UPDATE_MODEL_BY_CONTENT_LINK, SET_EPIMODEL_ISLOADING } from './types';

export function updateContext(payload) {
    return {
        type: UPDATE_CONTEXT,
        payload
    };
}

export function setEpiModelLoading(isLoading) {
    return {
        type: SET_EPIMODEL_ISLOADING,
        payload: isLoading
    };
}

export function updateModelByUrl(payload) {
    return {
        type: LOAD_EPIDATAMODEL,
        payload
    };
}

export function updateModelByContentLink(payload) {
    return {
        type: UPDATE_MODEL_BY_CONTENT_LINK,
        payload
    };
}
