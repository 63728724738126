import noTranslations from '../assets/translations/no.json';
import enTranslations from '../assets/translations/en.json';

export const translations = (lang) => {
    switch (lang) {
    case 'no':
        return noTranslations;
    case 'en':
        return enTranslations;
    default:
        return noTranslations;
    }
};

export default translations;
